import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { ContactData } from '../../../model/contact-data';
import { phoneMask, phonePattern } from '../../../app.constants';
import { NotifyService } from '../../../services/notify.service';
import { CallMeHttpService } from '../../../services/call-me-http.service';

@Component({
  selector: 'app-call-me-dialog',
  templateUrl: './call-me-dialog.component.html',
  styleUrls: ['./call-me-dialog.component.scss', './../../../../styles/_modal.scss']
})
export class CallMeDialogComponent implements OnInit {

  callMeForm: FormGroup;
  callMeData: ContactData = new ContactData();
  phoneMask = phoneMask;
  data: any;

  constructor(private formBuilder: FormBuilder,
              private activeModal: NgbActiveModal,
              private callMeHttpService: CallMeHttpService,
              private router: Router,
              private notifyService: NotifyService) {
  }

  ngOnInit() {
    this.initCallMeForm();
  }

  initCallMeForm() {
    this.callMeForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(phonePattern)])],
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  onCloseDialog(): void {
    this.activeModal.close();
  }

  callMe() {
    if (this.callMeForm.valid) {
      this.callMeHttpService.callMe(this.callMeData).subscribe(
          response => {
            if (_.eq(response.status, 'FAILED')) {
              this.notifyService.error('Can\'t process your request now. Please try later.', {
                closeWith: ['click', 'button'],
                timeout: 10000
              });
            } else {
              this.onCloseDialog();
              this.router.navigateByUrl('/your-request');
            }
          },
      );
    }
  }

}
