<footer class="py-5 main-block">
  <div class="container">

    <div class="row">
      <div class="col-6 col-sm-4 col-md-3 nav-footer">
        <span>Cities</span>
        <a routerLink="/business-class-flights-to/amsterdam">Amsterdam</a>
        <a routerLink="/business-class-flights-to/bangkok">Bangkok</a>
        <a routerLink="/business-class-flights-to/london">London</a>
        <a routerLink="/business-class-flights-to/madrid">Madrid</a>
        <a routerLink="/business-class-flights-to/paris">Paris</a>
        <a routerLink="/business-class-flights-to/rome">Rome</a>
        <a routerLink="/business-class-flights-to/shanghai">Shanghai</a>
        <a routerLink="/business-class-flights-to/tokyo">Tokyo</a>
      </div>

      <div class="col-6 col-sm-4 col-md-3 nav-footer">
        <span>Countries</span>
        <a routerLink="/business-class-flights-to/austria">Austria</a>
        <a routerLink="/business-class-flights-to/belgium">Belgium</a>
        <a routerLink="/business-class-flights-to/china">China</a>
        <a routerLink="/business-class-flights-to/france">France </a>
        <a routerLink="/business-class-flights-to/italy">Italy</a>
        <a routerLink="/business-class-flights-to/japan">Japan</a>
        <a routerLink="/business-class-flights-to/thailand">Thailand</a>
        <a routerLink="/business-class-flights-to/united-kingdom">United Kingdom</a>
      </div>

      <div class="col-12 col-sm-4 col-md-3 mt-3 mt-sm-0 nav-footer">
        <span>Company</span>
        <a routerLink="{{buildLink('flights', isBusinessLand)}}">Flights</a>
        <a routerLink="/hotels">Hotels</a>
        <a routerLink="/cruise">Cruises</a>
        <a routerLink="/corporate-private">Private Jets</a>
        <a routerLink="/our-team">Our Team</a>
        <a routerLink="/reviews">Customer reviews</a>
        <a routerLink="/app-page">App</a>
        <a routerLink="/corporate">Corporate</a>
        <a routerLink="/about-us">About</a>
        <a routerLink="/contact-us">Contact</a>
      </div>

      <div class="col-12 col-md-3 social-icons">
        <img class="ng-lazyloaded" ng-reflect-lazy-image="./assets/img/logo1.png" src="./assets/img/logo.svg">
        <a href="https://www.facebook.com/BusinessClassGuruCom/" target="_blank">
          <i class="fa fa-facebook-square fa-2x" aria-hidden="true"></i>
        </a>
        <a href="https://twitter.com/wsflights" target="_blank">
          <i class="fa fa-twitter-square fa-2x" aria-hidden="true"></i>
        </a>
        <a href="/">
          <i class="fa fa-rss-square fa-2x" aria-hidden="true"></i>
        </a>
        <a href="/">
          <i class="fa fa-instagram fa-2x" aria-hidden="true"></i>
        </a>
      </div>
   
    </div>

    <div class="col-xs-12 footer-icons">
      <div class="col-sm-12">
        <i class="geo-icon"></i>
        <i class="ssl-icon"></i>
        <i class="mastercard-icon"></i>
        <i class="visa-icon"></i>
        <i class="amex-icon"></i>
        <i class="discover-icon"></i>
        <i class="bbb-icon"></i>
        <i class="iatan-icon"></i>
      </div>
    </div>

    <div class="col-sm-12 footer-copyright">
      <p class="privacy-section text-white">
        <strong>*No Spam - only phone-exclusive deals. No purchase necessary.</strong>
        By providing your contact details and clicking on "Get a Free Quote" you agree to be contacted for travel information via automated
        phone and text messages and by email. Your consent to receive such messages is not a condition of purchase.
        We respect your <strong class="link" routerLink="/privacy-policy">privacy</strong>.
      </p>
      <p class="m-0 text-center text-white">
        © {{year}} Business Class Guru. All right reserved. CST # 2088142-40
      </p>
      <p class="m-0 text-center text-white">
        Use of this Website constitutes acceptance of the
        <a routerLink="/terms-and-conditions">Terms of Service</a> and
        <a routerLink="/privacy-policy">Privacy Policy</a>.
      </p>
    </div>
  </div>
</footer>
