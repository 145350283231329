import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trustpilot-grid',
  templateUrl: './trustpilot-grid.component.html',
  styleUrls: ['./trustpilot-grid.component.scss']
})
export class TrustpilotGridComponent implements OnInit {
  showTrustGrid=false;
  constructor() { }

  ngOnInit() {
  }

  activate(): void {
    setTimeout(() => {
      const trustbox = document.getElementById('trust-grid');
      window.Trustpilot.loadFromElement(trustbox);}, 0);
  }
}
